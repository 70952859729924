import router from "../router";

const utils = {
  movePage(path) {
    router.push({ path: path })
  },
  movePageQuery(path, query) {
    router.push({ path: path, query:query })
  },
  log(log){
    if(import.meta.env.MODE != 'production')
      console.log(log)
  }
};

export default utils;
