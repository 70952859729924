import axios from "axios";

let baseUrl = 'http://localhost:8080/api/v1'
if(import.meta.env.MODE != 'test')
  baseUrl = 'http://localhost:8080/api/v1'
else if(import.meta.env.MODE != 'production')
  baseUrl = 'http://localhost:8080/api/v1'

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
